import type { Epic } from 'behavior/types';
import type { ProductListPageAction } from './actions';
import type { LoadedSettings } from 'behavior/settings';
import type { CalculatedProduct, CalculatedVariantsProduct, ProductVariants } from './types';
import type { ProductSegment } from './queries.types';
import type { AppState } from 'behavior';
import {
  loadCalculatedFieldsQuery,
  loadVariantsCalculatedFieldsQuery,
  loadProductsGeneralInfoQuery,
  loadVariantsGeneralInfoQuery,
} from './queries';
import {
  PRODUCTLIST_CALCULATED_FIELDS_REQUESTED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
  PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED,
  productsUpdated,
  productsGeneralInfoLoaded,
  requestCalculatedFields,
} from 'behavior/pages/productList/actions';
import {
  map,
  takeUntil,
  mergeMap,
  switchMap,
  pluck,
  startWith,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { parseQuery } from 'utils/url';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { shallowEqual } from 'react-redux';

const productListPageEpic: Epic<ProductListPageAction> = (action$, state$, { api, logger, scope }) => {
  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
    filter(_ => isLocationChangedIgnoreScrolling(state$.value)),
  );

  const calculatedFieldsLoad$ = action$.pipe(
    ofType(PRODUCTLIST_CALCULATED_FIELDS_REQUESTED),
    mergeMap(({ payload: { options, variantsOnly } }) =>
      api.graphApi<CalculatedFieldsResponse>(selectCalculatedFieldsQuery(variantsOnly), { options }).pipe(
        map(data => productsUpdated(data.catalog.products.products)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  const productsGeneralInfoLoad$ = action$.pipe(
    ofType(PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED),
    withLatestFrom(state$),
    switchMap(([action, state]) => api.graphApi<ProductsGeneralInfoResponse>(loadProductsGeneralInfoQuery({
      isInsiteEditor: state.insiteEditor.initialized,
      isProductGroupingEnabled: (state.settings as LoadedSettings).product.productGrouping.isEnabled,
    }), {
      id: action.payload.listPageId,
      options: action.payload.options,
      loadLargeImages: scope === 'SERVER',
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }, { retries: 0 })
      .pipe(
        pluck('pages', 'productList', 'products', 'products'),
        mergeMap(products => of(
          productsGeneralInfoLoaded(
            products,
            action.payload.appendProducts,
            action.payload.options.page.size || products.length,
          ),
          unsetLoadingIndicator(),
        )),
        retryWithToast(action$, logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const variantsGeneralFieldsLoad$ = action$.pipe(
    ofType(PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED),
    mergeMap(action => api.graphApi<VariantsProductsGeneralInfoResponse>(loadVariantsGeneralInfoQuery, action.payload).pipe(
      mergeMap(data =>
        of(
          productsUpdated(data.catalog.products.products),
          requestCalculatedFields(action.payload.options, true),
        )),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  return merge(calculatedFieldsLoad$, productsGeneralInfoLoad$, variantsGeneralFieldsLoad$);
};

export default productListPageEpic;

function isLocationChangedIgnoreScrolling(state : AppState) {
  const { routing: { location, navigatingTo } } = state;
  if (!location)
    return true;

  if (navigatingTo == null)
    return false;

  const navigatingLocation = navigatingTo!.location;
  if (location.pathname !== navigatingLocation.pathname)
    return true;

  const query = parseQuery(location.search);
  const navigatingQuery = parseQuery(navigatingLocation.search);
  delete query.count;
  delete navigatingQuery.count;

  return !shallowEqual(query, navigatingQuery);
}

function selectCalculatedFieldsQuery(variantsOnly: boolean): string {
  return variantsOnly
    ? loadVariantsCalculatedFieldsQuery
    : loadCalculatedFieldsQuery;
}

type CalculatedFieldsResponse = {
  catalog: {
    products: {
      products: CalculatedProduct[] | CalculatedVariantsProduct[];
    };
  };
};

type ProductsGeneralInfoResponse = {
  pages: {
    productList: {
      products: {
        products: ProductSegment[];
      };
    };
  };
};

type VariantsProductsGeneralInfoResponse = {
  catalog: {
    products: {
      products: ProductVariants[];
    };
  };
};
