export function selectPropsFromPage({
  products,
  totalCount,
  selectedSorting,
  sortingEnabled = true,
  viewModeSwitchEnabled,
  selectedViewMode,
  pagingType,
  showThumbnails,
  lastViewedEnabled,
  relevanceAvailable,
  id,
  metaTitle,
}) {
  return {
    products,
    totalCount,
    selectedSorting,
    sortingEnabled,
    viewModeSwitchEnabled,
    selectedViewMode,
    pagingType,
    showThumbnails,
    lastViewedEnabled,
    relevanceAvailable,
    id,
    metaTitle,
  };
}

export function removeDuplicatedByProductGroup(updatedProducts){
  return updatedProducts.filter((product, index, self) => {
    if(!product.productGroup)
      return true;

    return index === self.findIndex(t => t.productGroup?.id === product.productGroup?.id)
  });
}
